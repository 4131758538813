import React from 'react'
import './Loading.css'

const Loading = () => {


  return (
   <>
    <div className="loading-wrapper">
      <div className="loading-container">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
   </>
  )
}

export default Loading