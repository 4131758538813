import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactImage from '../../Assets/Images/Contact.jpg'

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");

  const SendEmail = async () => {
    try {
      if (!name || !email || !service || !message) {
        toast.error("Please fill in all fields");
        return;
      }
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("service", service);
      formData.append("message", message);

      const response = await axios.post(
        "http://localhost:4000/api/contactus",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      setName("");
      setEmail("");
      setService("");
      setMessage("");
      toast.success("Contact Form Submitted");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    SendEmail();
  };

  return (
    <>

      <Box id="contactSection" paddingTop={'3rem'}
    paddingBottom={'2rem'}

   sx={{
    // backgroundImage:`linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0.2%, rgb(0,0, 0, 0.5) 20.9%),url(${ContactImage})`,
    // backgroundPosition: "center",
    //       backgroundSize: "cover",
    //       backgroundRepeat: "none",
    //       objectFit:'contain'
   backgroundColor:"rgb(246, 247, 251)"
   }}

    >
      <Box>
     <Box height={'2rem'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>


     </Box>


      <Grid

paddingTop={'2rem'}
paddingBottom={'2rem'}
        container
        justifyContent="space-around"
        alignItems="center"
        paddingLeft="10%"
        paddingRight="10%"
        sx={{
          "@media(min-width:769px) and (max-width:1024px)": {
            paddingLeft: "0%",
            paddingRight: "0%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          },

        }}
      >
        <Grid item lg={7} md={7} sm={7} xs={12}>
          {/* <Box sx={{color:'#261141'}}>
            <Typography variant="h2" sx={{ fontSize: "2rem", fontWeight: "600",  }}>
              Contact us &rarr;
            </Typography>
            <Typography sx={{ paddingTop: "1rem", color: "black", fontSize: "0.9rem" }}>
              Committed to excellence, we focus on delivering high-quality solutions. Your satisfaction drives our dedication to continuous improvement
            </Typography>
            <Box display="flex" alignItems="center" marginTop={'1rem'}>
              <PhoneIcon sx={{ color: "black", fontSize: "1rem" }} />
              <Typography sx={{ color: "black", fontSize: "1rem", cursor: "pointer" }} onClick={() => (window.location.href = "tel:+923130880216")}>
                +92 313 0880216
              </Typography>
            </Box>
            <Box display="flex" paddingTop="1rem" alignItems="center">
              <LocationOnIcon sx={{ color: "black", fontSize: "1rem" }} />
              <Typography sx={{ color: "black", fontSize: "1rem" }}>Lahore, Pakistan</Typography>
            </Box>
          </Box> */}
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={5} xl={7}>
          <Box sx={{ paddingTop: "1rem", paddingBottom: "1rem", display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center", minHeight: "30rem",  backgroundColor:'transparent', borderRadius:'15px' }}>
            <form style={{ width: "100%", paddingLeft: "1rem", paddingRight: "1rem", display: "flex", flexDirection: "column", alignItems: "center" }} onSubmit={submitHandler}>
              <TextField id="standard-basic" label="Name" variant="standard" style={{ width: "100%", marginBottom: "1.5rem" }} name="name" value={name} onChange={(e) => setName(e.target.value)} />
              <TextField id="standard-basic" label="Email" variant="standard" style={{ width: "100%", marginBottom: "1.5rem" }} name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              <TextField id="standard-basic" label="Service You Want" variant="standard" style={{ width: "100%", marginBottom: "1.5rem" }} name="service" value={service} onChange={(e) => setService(e.target.value)} />
              <TextField id="outlined-multiline-static" label="Message" multiline rows={4} variant="standard" style={{ marginBottom: "1.5rem", width: "100%" }} name="message" value={message} onChange={(e) => setMessage(e.target.value)} />
              <Button type="submit" variant="contained" sx={{ backgroundColor: "#261141", borderRadius: "25px", fontSize: "1.2rem", alignSelf: "center", "&:hover": { backgroundColor: "#40b298", transition: "background-color 0.3s ease-in-out", color: "white" } }}>
                Submit
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
      <Box height={'2rem'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>


</Box>

      </Box>
    </Box>


    </>
  );
};

export default ContactForm;
