import React from 'react'
import Footer from '../../Layouts/Footer'
import BlogSection from './BlogSection'
import CareerHeader from '../../Layouts/CareerHeader'
import CareerHeroSection from '../../Layouts/CareerHeroSection'
import BlogsHeroSection from '../../Layouts/BlogsHeroSection'
import useFetch from '../../hooks/useFetch'
import Loading from '../../Components/Loading'
import Header from '../../Layouts/Header'
import BlogHeader from '../../Layouts/BlogHeader'
import { Box } from '@mui/material'


const Blogs = ({blogs}) => {

  let {loading, data, error} =useFetch('http://localhost:5000/api/posts')
  if(loading) return <Loading/>
  if(error) return <Loading/>


  return (

    <>
<BlogHeader/>




<Box sx={{paddingTop:'100px'}}>

<BlogSection blogs={blogs?blogs:""}/>
</Box>

<Footer/>
    </>

  )
}

export default Blogs