import React from 'react'
import Header from '../../Layouts/Header'
import { Box, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
// import { ReactMarkdown } from 'react-markdown';
import Markdown from 'react-markdown'
import BlogsHeroSection from '../../Layouts/BlogsHeroSection'
import useFetch from '../../hooks/useFetch'
import Loading from '../../Components/Loading'
import Blogs from './Blogs'
import BlogHeader from '../../Layouts/BlogHeader'
import BlogSection from './BlogSection'

const BlogContent = ({blogs}) => {




    const {id}=useParams()

    let {loading, data, error} =useFetch('http://localhost:1337/api/blogs?populate=*')
    if(loading) return <Loading/>
    if(error) return <p>Error! in Fetching API of Blogs from Strapi</p>

    let blog={}
    if (blogs && blogs.data) {
        let arr = blogs.data.filter(blog => blog.id == id);
        blog = arr[0] || {};


    }else{
        blog={}
    }
    const { attributes } = blog;


  return (
   <>
  <Header/>

  <Box sx={{backgroundImage:`linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 30.2%, rgb(0,0, 0, 0.8) 90.9%),url(http://localhost:1337${blog.attributes.Image.data.attributes.url})`, backgroundPosition:'center', backgroundSize:'cover',  backgroundRepeat:'none', height:'100vh', width:'100%', display:'flex', color:'white', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>


<Typography variant='h1' fontSize={'4rem'} fontWeight={'550'}
sx={{
  width:'70%',
  '@media(max-width:480px)':{
fontSize:'2rem'

}

}}

>{attributes.Title} </Typography>






        </Box>

   <Box padding='5% 15%' backgroundColor='white'>





<Markdown
        components={{
          img: ({ node, ...props }) => (
            <img
              {...props}
              style={{ width: '100%', height: 'auto', padding:'1% 10%'}} // Adjust the width here
            />
          ),
          p: ({ node, ...props }) => (
            <p
              {...props}
              style={{ lineHeight: '2' }} // Adjust the line height here
            />

          ),
          h1: ({ node, ...props }) => (
            <h1
              {...props}
              style={{ lineHeight: '2' }} // Adjust the line height here
            />


          ),
          h2: ({ node, ...props }) => (
            <h2
              {...props}
              style={{ lineHeight: '2' }} // Adjust the line height here
            />


          ),
          h3: ({ node, ...props }) => (
            <h3
              {...props}
              style={{ lineHeight: '2' }} // Adjust the line height here
            />


          ),
          h4: ({ node, ...props }) => (
            <h4
              {...props}
              style={{ lineHeight: '2' }} // Adjust the line height here
            />


          ),
          h5: ({ node, ...props }) => (
            <h5
              {...props}
              style={{ lineHeight: '2' }} // Adjust the line height here
            />


          ),

          li: ({ node, ...props }) => (
            <li
              {...props}
              style={{ lineHeight: '2' }} // Adjust the line height here
            />
          ),
          ol: ({ node, ...props }) => (
            <ol
              {...props}
              style={{ lineHeight: '2' }} // Adjust the line height here
            />
          ),
          a: ({ node, ...props }) => (
      <a
        {...props}
        target='_blank'
        style={{ color: 'blue' }} // Change the link color here
      />
    ),



        }}
      >
{blog.attributes.content}</Markdown>
<p>
  {/* <Markdown className='line-break'>{`${blog.attributes.content.paragraph}`}</Markdown> */}

 <div>
 {/* <Markdown>
              {blog.attributes.content.map(paragraph => paragraph.children.map(text => text.text).join(' ')).join('\n\n')}
            </Markdown> */}
 </div>

</p>
<br/>
<br/>
<br/>

<div style={{display:'flex', alignItems:'center', justifyContent:'space-evenly'}}>


<Box height={'10%'} width={'15%'} borderRadius={'70%'}>
<img src={`http://localhost:1337${blog.attributes.authorimage.data.attributes.url}`}
height={'100%'}
width={'100%'}
style={{borderRadius:'50%'}}

/>

</Box>
<Typography variant='h5' fontSize={'1rem'} fontWeight={'500'}
sx={{
  width:'70%',
  '@media(max-width:480px)':{
fontSize:'2rem'

}

}}

>


Author:

{attributes.author} </Typography>
</div>



   </Box>

   <Box backgroundColor='white'>
   <Typography variant="h4" gutterBottom paddingLeft={'10%'}>
            Recent Blogs
          </Typography>
          {/* Pass only the first 3 blogs to BlogSection component */}
          <BlogSection blogs={{ data: data.data.slice(0, 3) }} />
        </Box>

   </>
  )
}

export default BlogContent