import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Joinus from "../../Assets/Images/Joinus.svg";

const MobJoinUs = () => {
  return (
    <>

<Box
        sx={{
display:'flex',
justifyContent:'center',
alignItems:'center',
          minHeight: "30rem",
backgroundColor:'white',
          "@media(max-width:600px)": {
            minHeight: "40rem",
          },
          "@media(min-width:601px) and (max-width:768px)": {
            minHeight: "25rem",
display:'none'
          },
          "@media(min-width:769px) and (max-width:1024px)": {
            minHeight: "25rem",
            display:'none'
          },
          "@media(min-width:1024px)": {

            display:'none'
          },
        }}
      >
        <Grid container paddingLeft={"10%"} paddingRight={"10%"}>

          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",

            }}
          >

             <div>
             <Typography
                variant="h1"
                sx={{
                  fontSize: "2rem",
                  paddingLeft: "2rem",
                  color: "#261141",

                  "@media (max-width:600px)": {
                    fontSize: "1.5rem",
padding:'0rem'
                  },
                  "@media(min-width:601px) and (max-width:768px)": {
                    fontSize: "1.8rem",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                  },
                  "@media(min-width:769px) and (max-width:1024px)": {
                    fontSize: "2rem",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                  },
                }}
              >
                Take your career to the next level{" "}
              </Typography>
              <Typography
                sx={{
                  padding: "2rem",
                  fontSize: "1rem",

                  "@media(max-width:600px)":{
padding:'0rem',
paddingTop:'1rem'
                  },
                  "@media(min-width:601px) and (max-width:768px)": {
                    fontSize: "0.8rem",
                    paddingTop: "1rem",
                    paddingLeft: "10%",
                  },
                  "@media(min-width:769px) and (max-width:1024px)": {
                    fontSize: "0.8rem",
                    paddingLeft: "10%",
                  },
                }}
              >
                Remcosol Technologies is one of the most sought-after employers
                in the industry. Apply now and become a part of the Remcosol's
                family
              </Typography>
              <Link to={"/career"}>
                <Button
                  variant="outlined"
                  sx={{
                    marginLeft: "2rem",
                    borderRadius: "12px",
                    fontSize: "1.1rem",

                    color: "black",

                    borderColor: "#261141",
                    "@media(max-width:600px)":{
marginLeft:'0rem',
marginTop:'1rem'
                  },
                    "@media(min-width:601px) and (max-width:768px)": {
                      fontSize: "0.8rem",
                    },
                    "@media(min-width:769px) and (max-width:1024px)": {
                      fontSize: "0.8rem",
                    },
                  }}
                >
                  Join Us &rarr;
                </Button>
              </Link>

             </div>
          </Grid>


          <Grid item lg={6} md={6} sm={6} xs={12}
            zIndex={'100'}

          sx={{
            "@media(max-width:600px)": {
                paddingTop: "4rem",

              },
          }}

          >
            <div
              class="afterglowcard"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "auto",
              }}
            >
              <img
                src={Joinus}
                height={"100%"}
                width={"100%"}
                alt="Dedicated Team"
              />
            </div>
          </Grid>

        </Grid>
      </Box>
    </>
  )
}

export default MobJoinUs