import React from 'react'
import Header from '../../Layouts/Header'
import Footer from '../../Layouts/Footer'
import BlogContent from './BlogContent'

const MainContent = ({blogs}) => {
    console.log('mainnnnn')
    console.log(blogs)
  return (
    <>
        <Header/>
        <BlogContent blogs={blogs}/>
        <Footer/>
    </>
  )
}

export default MainContent