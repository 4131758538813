import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Button } from '@mui/material';
import axios from 'axios'

const BlogSection = () => {
// const BlogSection = ({ blogs }) => {

  // Check if blogs or blogs.data is undefined or null
  // if (!blogs || !blogs.data) {
  //   return <div>No blogs to display.</div>;
  // }

  // // Check if blogs.data is an array
  // if (!Array.isArray(blogs.data)) {
  //   return <div>Invalid data structure for blogs.</div>;
  // }


  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deletedBlogId, setDeletedBlogId] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/posts");
        setBlogs(response.data);
      } catch (error) {
        setError("Error fetching blogs");
      }
    };

    fetchBlogs();
  }, []);

  return (
    <Box padding='5% 10%'>
      <Grid container spacing={3}>
        {blogs.map((blog) => (
          <Grid key={blog.id} item xs={12} sm={6} md={4} lg={4}>
            <Link to={`/content/${blog.id}`}>
              <Card sx={{ maxWidth: 345, minHeight: '27rem', }}>
                <CardActionArea >
                  <CardMedia
                    component="img"
                    height="200"
                    // image={`http://localhost:1337${blog.attributes.Image.data.attributes.url}`}
                    alt="Blog Image"
                    style={{ objectFit: 'cover',     }}
                  />
                  <CardContent sx={{minHeight:'10rem'}}>
                    <Typography gutterBottom variant="h5" component="div" fontSize={'1rem'}>
                      {/* {blog.attributes.Title} */}
                      {blog.title}

                    </Typography>
                    <Typography variant="body2" color="text.secondary" style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>
                      {/* {blog.attributes.desc} */}
                      {blog.shortdescription}

                    </Typography>
                  </CardContent>
                </CardActionArea>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Button sx={{ width:'80%'}} variant='contained' onClick={()=>`/content/${blog.id}`}>Read More</Button>
                </Box>

              </Card>

            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BlogSection;
