import { Button } from "@mui/material";
import React, { useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { Link as Li } from "react-router-dom";


function BlogHeader() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const [navbar, setNavbar] = useState(false);

  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else {
      setActive("nav__menu");
    }

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else {
      setIcon("nav__toggler");
    }
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
    navToggle(); // Close the menu when scrolling to top
  };

  const changeBackground = () => {
    if (window.scrollY >= "12") {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <nav className="blognav">
        <div className="blognav__brand">
          <a href="/" onClick={scrollToTop}>
            RemcoSol Tech
          </a>
        </div>
        <ul className={active}>
          <li className="nav__item active1">
          <Li className="nav__link"
              style={{ cursor: "pointer" }}
              onClick={navToggle}
           to='/'

          >
    Home
  </Li>
          </li>
          <li className="nav__item active1">
            <Link
              style={{ cursor: "pointer" }}
              activeClass="active"
              to="services"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="nav__link"
              onClick={navToggle} // Close the menu when a link is clicked
            >
              Services
            </Link>
          </li>


          <li className= "nav__item active1" >
          <Li className="nav__link"
              style={{ cursor: "pointer" }}
              onClick={navToggle}
           to='/career'

          >
    Career
  </Li>
</li>
  <li className="nav__item active1">
          <Li className="nav__link"
              style={{ cursor: "pointer" }}
              onClick={navToggle}
           to='/blogs'

          >
    Blogs
  </Li>
</li>
          <li className="nav__item active1" >
          <Li className="nav__link"
              style={{ cursor: "pointer" }}
              onClick={navToggle}
           to='/developing'

          >
    Solutions
  </Li>
          </li>


          <Button
  variant="contained"
  sx={{
    cursor: "pointer",
    borderRadius: "12px",
    fontSize: "1.1rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    "&:hover": {
      backgroundColor: "#40b298",
      color: "white",
    },
    "@media(max-width:480px)": {
      backgroundColor: "#40b298",
    },
  }}

>
  <Link to="contactSection" spy={true} smooth={true} offset={-70} duration={500}
   onClick={navToggle}
  >
    Contact us
  </Link>
</Button>


          {/* Add more links for other sections */}
        </ul>
        <div onClick={navToggle} className={icon}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>


      </nav>


    </>
  );
}

export default BlogHeader;
