import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

const Footer = () => {
  return (
    <>


      <Grid
        container
        spcing={3}
// backgroundColor='#f6f7fb'
backgroundColor='white'
        paddingTop={'3rem'}
        paddingLeft={"8rem"}
        paddingRight={"8rem"}
        paddingBottom={"1rem"}
        sx={{
          "@media(max-width:600px)": {
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
            paddingTop:'1rem'
          },
          "@media(min-width:600px) and (max-width:900px)": {
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
            paddingTop:'1rem'
          },
          "@media(min-width:901px) and (max-width:1024px)": {
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingTop:'1rem'
          },
        }}
      >
        <Grid item xs={12} sm={5} md={5} lg={5}>
          <Box
            sx={{
            color:'#261141',
              paddingTop: "3rem",
              paddingLeft: "2rem",
              height: "100%",
            }}
          >
            <h2>Remcosol Technologies</h2>
            <h3 style={{fontSize:'0.9rem', color:'black'}}>Technology You can Trust</h3>
            <br/>

            <Typography sx={{paddingRight:'10%',
            color:'black'
,


            }}>Remcosol Technologies is a forward-thinking web and AI software development company dedicated to shaping the future of technology. Our passionate team of experts specializes in creating cutting-edge solutions that meet the evolving needs of businesses across diverse industries.</Typography>


          </Box>
        </Grid>

        <Grid item xs={5} sm={3} md={3} lg={3.5}>
          <Box
            sx={{
              color: "black",
              paddingTop: "3rem",
              paddingLeft: "2rem",
              height: "100%",
              '@media(max-width:600px)':{
                fontSize:'1rem'
              }            }}
          >
            <h3 style={{color:'#261141'}}>Services</h3>
            <Typography marginBottom={'0.5rem'} marginTop={'1.5rem'}
            sx={{
              cursor:'pointer'
,
              '@media(max-width:480px)':{
              fontSize:'0.9rem'
            }}}


            >UI/UX Design</Typography>

            <Typography marginBottom={'0.5rem'}
             sx={{'@media(max-width:480px)':{
              fontSize:'0.9rem'
            },
cursor:'pointer'

            }}


            >Web development</Typography>
            <Typography marginBottom={'0.5rem'}
             sx={{'@media(max-width:480px)':{
              fontSize:'0.9rem'
            },
cursor:'pointer'
            }}


            >Generative AI</Typography>

            <Typography marginBottom={'0.5rem'}
             sx={{'@media(max-width:480px)':{
              fontSize:'0.9rem'
            },
cursor:'pointer'
            }}
            >Mobile Apps Development</Typography>
            <Typography
             sx={{'@media(max-width:480px)':{
              fontSize:'0.9rem'
            },
cursor:'pointer'
            }}

            >Analytics</Typography>


          </Box>
        </Grid>

        <Grid item xs={7} sm={3} md={3} lg={3.5}
          sx={{
            '@media(max-width:480px)':{
              paddingRight:'1rem'
            }
          }}

        >
          <Box
            sx={{
              color: "black",
              paddingTop: "3rem",
              paddingLeft: "2rem",
              height: "100%",
            }}
          >
            <h3 style={{color:'#261141'}}>Get in Touch</h3>
            <Box display={"flex"}  marginTop='1.3rem'>

              <PhoneIcon

sx={{'@media(max-width:480px)':{
              fontSize:'1rem'
            }}}


              />
             <Typography
  sx={{
    '@media(max-width:480px)': {
      fontSize: '0.7rem',
    },
    cursor: 'pointer', // Add this to change the cursor to a pointer
  }}
  onClick={() => window.location.href = 'tel:+923334149021'}
>
  +92 333 4149021
</Typography>
            </Box>

            <Box display={"flex"} marginTop={'0.5rem'}

            >
              <EmailIcon

sx={{'@media(max-width:480px)':{
              fontSize:'1rem'
            }}}

              />
              <Typography
               sx={{'@media(max-width:480px)':{
              fontSize:'0.7rem'
            }}}
              >info@remcosol.com</Typography>
            </Box>
            <br/>


   <br />
            <Typography

sx={{'@media(max-width:480px)':{
              fontSize:'0.9rem'
            }}}
            >For Job Opportunity</Typography>

            <Box sx={{ display: "flex" }}>
              <EmailIcon  sx={{'@media(max-width:480px)':{
              fontSize:'1rem'
            }}}/>
             <Typography
  sx={{
    '@media(max-width:480px)': {
      fontSize: '0.7rem',
    },
    cursor: 'pointer', // Add this to change the cursor to a pointer
  }}
  onClick={() => window.location.href = 'mailto:info@remcosol.com'}
>
  info@remcosol.com
</Typography>

            </Box>
            
          </Box>
        </Grid>
        <hr style={{width:'100%', marginTop:'2rem'}}/>


      </Grid>
      <Box sx={{backgroundColor:'white', display:'flex', color:'#261141', paddingLeft:'10rem', minHeight:'5vh', paddingTop:'1rem', paddingBottom:'2rem', justifyContent:'space-between', paddingRight:'15rem', alignItems:'center',
      '@media(max-width:600px)':{
        paddingLeft:'0rem',
        paddingRight:'0rem',
        flexDirection:'column'
      },
      "@media(min-width:600px) and (max-width:900px)": {
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingTop:'1rem'
          },

      }}>

  <Typography
   sx={{ color:'black','@media(max-width:480px)':{
              fontSize:'0.8rem'
            }


            }}
  >© 2023 Remcosol Technologies. All rights reserved.</Typography>
  <Box display={'flex'} justifyContent='space-between' width='10vw'
   sx={{'@media(max-width:480px)':{
              justifyContent:'center',
              alignItems:'center'
            }}}


  >


  <FacebookIcon sx={{fontSize:'2.5rem', cursor:'pointer',

'@media(max-width:480px)':{
              fontSize:'2rem'
            }}}

/>
  <LinkedInIcon sx={{fontSize:'2.5rem',cursor:'pointer',
  '@media(max-width:480px)':{
              fontSize:'2rem'
            }

  }}/>
  <TwitterIcon  sx={{fontSize:'2.5rem',cursor:'pointer',
'@media(max-width:480px)':{
              fontSize:'2rem'
            }

  }}/>

  </Box>

</Box>

    </>
  );
};

export default Footer;
