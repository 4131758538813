import React from "react";
import { Box, Button, Typography } from "@mui/material";
import HeroSectionImage from "../Assets/Images/Header_Image.jpg";
import { Typewriter } from "react-simple-typewriter";

const Hero_Section = () => {
  return (
    <>
      <Box
        sx={{
          backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 1) 30.2%, rgb(0,0, 0, 0.5) 90.9%),url(${HeroSectionImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "none",
          height: "100vh",
          width: "100%",
        }}
      >
        <Box
          sx={{
            color: "white",
            paddingLeft: "10%",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",


            "@media(max-width:480px)": {
              paddingLeft: "2rem",
              paddingRight: "3rem",
            },
            "@media(min-width:481px) and (max-width:768px)": {
              paddingLeft: "10%",
              paddingRight: "10%",
            },

            "@media(min-width:769px) and (max-width:1024px)": {
              paddingLeft: "10%",
              paddingRight: "10%",
            },
          }}
        >
          <Box
            minHeight={"8rem"}

            sx={{
              "@media(max-width:480px)": {
                minHeight: "12rem",
              },
            }}
          >
            <Typography
              variant="h3"
              fontSize={"2rem"}
              fontWeight={"750"}
              width="50%"
              sx={{
                "@media(max-width:480px)": {
                  fontSize: "1.5rem",
                  width: "100%",
                },
                "@media(min-width:480px) and (max-width:900px)": {
                  fontSize: "2rem",
                  width: "80%",
                },
              }}
            >
              Redefine Your Business Processes With Our Powerful Automated
              Services
            </Typography>

            <Typography
              sx={{
                fontSize: "1.5rem",
                "@media(max-width:480px)": {
                  fontSize: "1.2rem",
                },
                "@media(min-width:4810px) and (max-width:900px)": {
                  fontSize: "1.8rem",
                },
              }}
            >
              For those who
              <span style={{ color: "white" }}>
                {/* Style will be inherited from the parent element */}
                <Typewriter
                  words={[
                    " need technology to reveal the beauty of products.",
                    " need to build complex projects.",
                    " are striving for quality at all stages.",
                  ]}
                  loop={Infinity}
                  typeSpeed={60}
                  deleteSpeed={40}
                  delaySpeed={1600}
                />
              </span>
            </Typography>
          </Box>

          <Button
            variant="outlined"
            sx={{
              marginTop: "2rem",
              color: "white",
              borderColor: "transparent",
              cursor: "pointer",
              fontSize: "1rem",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
              borderRadius: "25px",
              position: "relative",
              overflow: "hidden",
              border:'1px solid white',
              "&:hover": {
                borderColor: "#40b298",
                backgroundColor: "#40b298",
                outline: "none",
              },
            }}
          >
            See More &rarr;{" "}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Hero_Section;
