// Career.js
import React from "react";
import CareerHeader from "../../Layouts/CareerHeader";
import CareerHeroSection from "../../Layouts/CareerHeroSection";
import OpenJobs from "./OpenJobs";
import Footer from '../../Layouts/Footer'
import WhyJoinUs from "./WhyJoinUs";

function Career() {
  return (
    <>
<CareerHeader/>
<CareerHeroSection/>
<WhyJoinUs/>
<OpenJobs/>
<Footer/>


    </>

  );
}

export default Career;
