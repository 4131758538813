import React, {useState} from 'react'
// import Slider from "react-slick";
import ReactIcon from "../../Assets/Images/Technologies/React.svg";
import Python from "../../Assets/Images/Technologies/Python.svg";
import IOS from "../../Assets/Images/Technologies/iOS.svg";
import Postgre from "../../Assets/Images/Technologies/postgre.svg";
import SQL from "../../Assets/Images/Technologies/MySQL.svg";
import Azure from "../../Assets/Images/Technologies/MSAzure.svg";
import FastAPI from '../../Assets/Images/Technologies/fastapi.svg';
import Node from '../../Assets/Images/Technologies/NodeJS.svg';
// import Android from '../../Assets/Images/Technologies/android.png';
// import Pytorch from '../../Assets/Images/Technologies/pytorch.png';
import TensorFlow from '../../Assets/Images/Technologies/tensorflow.svg';
// import MUI from '../../Assets/Images/Technologies/MUI.png';




import { Box, Button, Typography } from '@mui/material';

const Expertise = () => {
   
      const [activeSection, setActiveSection] = useState(1);

      const handleButtonClick = (sectionNumber) => {
        setActiveSection(sectionNumber);
      };


  return (
    <>


      <Box sx={{paddingLeft:'10%', paddingRight:'10%', paddingTop:'4rem',

      backgroundColor:'white'
      ,'@media(max-width:769px)':{
        display:'none'
      }

      }}>
      <Box sx={{ width:'100%', paddingBottom:'3rem' ,



        }}>
 <Typography
          variant="h1"
          sx={{
            fontWeight: "600",
            fontSize: "1.8rem",
            color:'#261141',
            "@media(max-width:480px)": {
              fontSize: "1.2rem",
            },
          }}
          className="line"
        >
          Technologies
        </Typography>

        </Box>
      <div >
        <Button sx={{marginRight:'1rem', fontSize:'1rem', paddingTop:'0.5rem', paddingBottom:'0.5rem', borderRadius:'25px'}}
          variant={activeSection === 1 ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleButtonClick(1)}
        >
          Web Development
        </Button>
        <Button sx={{marginRight:'1rem', fontSize:'1rem', paddingTop:'0.5rem', paddingBottom:'0.5rem', borderRadius:'25px'}}
          variant={activeSection === 2 ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleButtonClick(2)}
        >
          Mobile Development
        </Button>
        <Button
        sx={{ fontSize:'1rem', paddingTop:'0.5rem', paddingBottom:'0.5rem', borderRadius:'25px'}}
          variant={activeSection === 3 ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleButtonClick(3)}
        >
          Generative AI
        </Button>
      </div>
      <div style={{marginTop:'0.5rem'}}>
        {activeSection === 1 &&
<Box sx={{minHeight:'5rem', }}>

<img src={ReactIcon} className='imgbg' height={'100%'} width={'12%'} alt='React JS'/>
{/* <img src={MUI} className='imgbg' height={'10%'} width={'12%'}/> */}
<img src={SQL} className='imgbg' height={'100%'} width={'12%'} alt='SQL'/>
<img src={Azure} className='imgbg' height={'100%'} width={'12%'} alt='Azure'/>
<img src={Postgre} className='imgbg' height={'100%'} width={'12%'} alt='PostgreSQL'/>
<img src={Node} className='imgbg' height={'100%'} width={'12%'} alt='Node'/>
<img src={Python} className='imgbg' height={'100%'} width={'12%'} alt='Python'/>




</Box>


        }
        {activeSection === 2 &&
          <Box sx={{minHeight:'15rem', }}>

<img src={ReactIcon} className='imgbg' height={'100%'} width={'12%'} alt='React JS'/>
<img src={IOS} className='imgbg' height={'100%'} width={'12%'} alt='IOS'/>
{/* <img src={Android} className='imgbg' height={'100%'} width={'12%'}/> */}
<img src={Node} className='imgbg' height={'100%'} width={'12%'} alt='Node JS'/>
<img src={Python} className='imgbg' height={'100%'} width={'12%'} alt='Python'/>
<img src={SQL} className='imgbg' height={'100%'} width={'12%'} alt='SQL'/>
<img src={Azure} className='imgbg' height={'100%'} width={'12%'} alt='Azure'/>
<img src={Postgre} className='imgbg' height={'100%'} width={'12%'} alt='PostgreSQL'/>



</Box>
        }
        {activeSection === 3 &&
          <Box sx={{minHeight:'15rem', }}>

<img src={Python} className='imgbg' height={'100%'} width={'12%'} alt='Python'/>
<img src={TensorFlow} className='imgbg' height={'100%'} width={'12%'} alt='Tensorflow'/>
{/* <img src={Pytorch} className='imgbg' height={'100%'} width={'12%'}/> */}
<img src={FastAPI} className='imgbg' height={'100%'} width={'12%'} alt='FAST API'/>





</Box>
        }
      </div>
    </Box>


    </>
  )
}

export default Expertise