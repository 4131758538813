import { Button, Box, Grid, Typography } from "@mui/material";
import React from "react";
import Team from "../../Assets/Images/Teradata.svg";

const DedicatedTeam = () => {
  return (
    <>
      <Box
        sx={{
          background: "white",

          "@media(max-width:480px)": {
            paddingTop: "1rem",
          },
          "@media(min-width:481px) and (max-width:768px)": {
                     paddingTop:'3rem'
                    },
                    "@media(min-width:769px) and (max-width:1024px)": {
                     paddingTop:'3rem'
                    },
        }}
      >
        <Box
          sx={{
            paddingLeft: "10%",
            paddingRight: "10%",
            minHeight: "10rem",
            paddingBottom: "3rem",
            color: "#261141",
            "@media(max-width:480px)": {
              paddingTop: "4rem",
            },
          }}
        >
          <Grid container alignItems={"center"}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box
                width={"85%"}
                sx={{
                  "@media(max-width:480px)": {
                    width: "100%",
                  },
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontWeight: "500",
                    fontSize: "2rem",

                    "@media(max-width:480px)": {
                      fontSize: "1.5rem",
                    },
                    "@media(min-width:480px) and (max-width:768px)": {
                      fontSize: "1.5rem",
                    },
                    "@media(min-width:769px) and (max-width:1024px)": {
                      fontSize: "1.5rem",
                    },
                  }}
                >
                  Revolutionizing your business with cutting edge software
                  solution
                </Typography>
                <br />
                <Typography
                  fontSize={"1.2rem"}
                  sx={{
                    "@media(max-width:480px)": {
                      fontSize: "1.2rem",
                    },
                    "@media(min-width:480px) and (max-width:768px)": {
                      fontSize: "1.2rem",
                    },
                    color: "black",
                  }}
                >
                  <ul style={{ paddingLeft: "20px" }}>
                    <li style={{ position: "relative" }}>
                      <span
                        style={{
                          color: "#40b298",
                          position: "absolute",
                          left: "-20px",
                        }}
                      >
                        &bull;
                      </span>
                      Power AI innovation
                    </li>
                    <li style={{ position: "relative" }}>
                      <span
                        style={{
                          color: "#40b298",
                          position: "absolute",
                          left: "-20px",
                        }}
                      >
                        &bull;
                      </span>
                      Enjoy easier Collaboration
                    </li>
                    <li style={{ position: "relative" }}>
                      <span
                        style={{
                          color: "#40b298",
                          position: "absolute",
                          left: "-20px",
                        }}
                      >
                        &bull;
                      </span>
                      Develop solutions faster
                    </li>
                  </ul>
                </Typography>
                <br />
                <br />
                <Button
                  type="submit"
                  variant="outlined"
                  sx={{
                    backgroundColor: "white",
                    alignSelf: "center",
                    fontSize: "1.1rem",
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                    borderRadius: "25px",

                    color: "black",
                    "@media(max-width:480px)": {
                      fontSize: "1rem",
                    },
                    "@media(min-width:480px) and (max-width:768px)": {
                      fontSize: "1rem",
                    },
                  }}
                >
                  Empower Your business &rarr;
                </Button>
              </Box>
            </Grid>
            <Grid
            padding='3rem'
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              zIndex={"100"}
              sx={{
                "@media(max-width:480px)": {
                  marginTop: "2rem",
                  padding:'0rem'
                },
              }}
            >
              <div
                class="afterglow"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "auto",
                }}
              >
                <img

                  src={Team}
                  height={"100%"}
                  width={"100%"}
                  alt="Dedicated Team"
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default DedicatedTeam;
