// ApplyFormModal.js
import { Button, Box, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UploadIcon from "../../Assets/Images/upload.png";


Modal.setAppElement("#root"); // Set the root element for accessibility

function ApplyFormModal({ isOpen, onRequestClose }) {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [position, setPosition] = useState('');
  const [send, setSend] = useState(null); // Initialize send state as null
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('Upload Resume');



  const SendEmail = async () => {
    try {
      // Validate form fields
      if (!name || !email || !phone || !position || !selectedFile) {
        toast.error('Please fill in all fields and select a file.');
        return;
      }

      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('position', position);
      formData.append('resume', selectedFile); // Add the selected file to the form data
      formData.append('recipientEmail', email);


        const response = await axios.post("http://localhost:4000/api/send", formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set content type for file upload
        },
      });

      // Handle the response or set the send state as needed
      setSend(response.data);

      // Reset form fields
      setName('');
      setEmail('');
      setPhone('');
      setPosition('');

      setSelectedFile(null); // Clear the selected file
document.getElementsByClassName('filename').value = '';
      // Display a success message
      toast.success('Form submitted successfully');
    } catch (error) {
      // Handle errors
      console.error(error);
      toast.error('An error occurred while submitting the form.');
    } finally {
      // Set buttonLoading to false whether the request succeeded or failed
      setButtonLoading(false);
    }
  };
  const submitHandler = (e) => {


    e.preventDefault();
    setButtonLoading(true);
    SendEmail();
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    // Update the label text to the selected file name
    if (file) {
      setSelectedFileName(file.name);
    } else {
      setSelectedFileName('Upload Resume'); // Reset to default text
    }
  };






  const [modalWidth, setModalWidth] = useState("35%");


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setModalWidth("80%");
      }
      if (window.innerWidth <= 769) {
        setModalWidth("80%");
      }
      else {
        setModalWidth("35%");
      }
    };

    // Initial check
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Modal
      style={{
        content: {
          width: modalWidth,
          margin: "0 auto",
          top: "55%",
          transform: "translateY(-50%)",
          display: "flex",
          minHeight: "35rem",
          flexDirection: "column",
        },
      }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Apply Form"
    >

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <form
          encType="multipart/form-data"
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          onSubmit={submitHandler}
        >
          <h2>Apply Now</h2>
          <TextField
            id="standard-basic"
            label='Name'
            variant="standard"
            style={{
              width: '90%',
              marginLeft: '1rem',
              color: 'white',
              marginBottom: '1.5rem',
            }}
            name="name" // Add 'name' attribute for form field identification
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            id="standard-basic"
            label='Email'
            variant="standard"
            style={{
              width: '90%',
              marginLeft: '1rem',
              color: 'white',
              marginBottom: '1.5rem',
            }}
            name="email" // Add 'name' attribute for form field identification
            value={email}

            onChange={(e) => setEmail(e.target.value)}

          />
          <TextField
            id="standard-basic"
            label='Phone Number'
            variant="standard"
            style={{
              width: '90%',
              marginLeft: '1rem',
              color: 'white',
              marginBottom: '1.5rem',
            }}
            name="phone" // Add 'name' attribute for form field identification
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <TextField
            id="outlined-multiline-static"
            label='Position Applied For '
            multiline
            variant="standard"
            style={{
              marginBottom: '2rem',
              width: '90%',
              marginLeft: '1rem',
              color: 'white',
            }}
            name="position" // Add 'name' attribute for form field identification
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          />


<label htmlFor="fileInput" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={UploadIcon}
              alt="File Icon"
              style={{ width: '24%', height: 'auto', cursor: 'pointer', paddingLeft: '1.5rem' }}
            />
            {selectedFileName} {/* Display the selected file name here */}
          </label>
          <input
            id="fileInput"
            type="file"
            className="filename"
            accept=".pdf, .doc, .docx"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />





<br/>


          <div>
            <Button
              variant="contained"
              onClick={onRequestClose}
              style={{ backgroundColor: '#261141', alignSelf: 'center', fontSize:'1rem' }}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: '#261141', alignSelf: 'center', marginLeft: '1rem', fontSize:'1rem' }}
            >
              Submit
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}

export default ApplyFormModal;
